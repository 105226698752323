export enum Experiments {
  P2P = "p2p",
  AUTO_LIQUIDATION_MAINNET = "auto_liquidation_mainnet",
  AUTO_LIQUIDATION_SEPOLIA = "auto_liquidation_sepolia",
  AUTO_LIQUIDATION_POLYGON = "auto_liquidation_polygon",
  AUTO_LIQUIDATION_BLAST = "auto_liquidation_blast",
  AUTO_LIQUIDATION_BLAST_SEPOLIA = "auto_liquidation_blast_sepolia",
  AUTO_LIQUIDATION_VAULT = "auto_liquidation_vault",
  UI_V2 = "ui_v2",
}
